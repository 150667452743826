// 组件
import importImg from '@/components/importImg.vue'
// 接口
import * as shopsApi from '@/services/shops.js'
import * as shopCategoriesApi from '@/services/shop-categories.js'
import * as shopSubCategoriesApi from '@/services/shop-sub-categories.js'
// 其他
import dataDictionary from '@/utils/data-dictionary.js'
import constant from '@/utils/constant.js'
import verifyRules from '@/utils/verify-rules/index.js'
import loading from '@/utils/loading.json'

export default {
  components: {
    importImg
  },
  data () {
    return {
      app_name: process.env.VUE_APP_NAME,
      constant,
      dataDictionary,
      verifyRules: verifyRules(this),
      urlParams: null,
      shopList: [],
      subCategorieList: [],
      mainForm: {
        cover: null,
        subCategorieSelect: {
          loading: false
        },
        data: {
          online: true,
          shop_id: null,
          name: null,
          name_en: null,
          remote_icon_url: null,
          short_description: null,
          short_description_en: null,
          rank: null,
          compulsory_items: false,
          category_sub_categories_attributes: [],
          wholesale_type: 'retail'
        }
      },
      wholesaleTypeList: [
        {
          value: 'retail',
          name: this.$t('form.product.retail')
        },
        {
          value: 'wholesale',
          name: this.$t('form.product.wholesale')
        },
        {
          value: 'retail_and_wholesale',
          name: this.$t('form.product.retailAndwholesale')
        }
      ]
    }
  },
  created () {
    const _this = this
    _this.urlParams = _this.$route.params
  },
  mounted () {
    const _this = this
    const initRequest1 = shopsApi.getShopsSimpleList().then(res => {
      _this.shopList = res.data.shops
      return res
    }).catch(err => err)
    const initRequest2 = shopCategoriesApi.getIdPreview(_this.urlParams.id).then(res => {
      const resData = res.data.shop_category
      return _this.handleMainFormShopSelectChange(resData.shop_id).then(() => {
        Object.assign(_this.mainForm.data, resData)
        _this.mainForm.data.remote_icon_url = resData.icon
        _this.mainForm.cover = [{ id: Date.now(), image_url: resData.icon }]
        if (resData.category_sub_categories && resData.category_sub_categories.length > 0) {
          _this.mainForm.data.category_sub_categories_attributes = resData.category_sub_categories.map(item => {
            return {
              ...item,
              _destroy: false
            }
          })
        } else {
          _this.mainForm.data.category_sub_categories_attributes = []
        }
        delete _this.mainForm.data.category_sub_categories
        return res
      }).catch(err => err)
    }).catch(err => err)
    _this.setGlobalLoading(true)
    Promise.all([initRequest1, initRequest2]).then(() => {
      _this.setGlobalLoading(false)
    }).catch(() => {
      _this.setGlobalLoading(false)
    })
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    getSubCategoriesDefaultData () {
      return {
        shop_sub_category_id: null
      }
    },
    handleMainFormSubCategoriesAddBtnClick () {
      const _this = this
      _this.mainForm.data.category_sub_categories_attributes.push(_this.getSubCategoriesDefaultData())
    },
    handleMainFormSubCategoriesItemDelBtnClick (data, index) {
      const _this = this
      if (data._destroy !== undefined) {
        _this.mainForm.data.category_sub_categories_attributes[index]._destroy = true
      } else {
        _this.mainForm.data.category_sub_categories_attributes.splice(index, 1)
      }
    },
    handleMainFormImgChange (fileData) {
      const _this = this
      if (fileData && fileData.length > 0 && !fileData[0]._destroy) {
        _this.mainForm.data.remote_icon_url = fileData[0].url
      } else {
        _this.mainForm.data.remote_icon_url = null
      }
    },
    handleMainFormShopSelectChange (val) {
      const _this = this
      _this.subCategorieList = []
      _this.mainForm.data.category_sub_categories_attributes = []
      _this.mainForm.subCategorieSelect.loading = true
      return shopSubCategoriesApi.getSimpleListByShopId({
        shop_id: val
      }).then(res => {
        _this.mainForm.subCategorieSelect.loading = false
        _this.subCategorieList = res.data.shop_sub_categories
      }).catch(() => {
        _this.mainForm.subCategorieSelect.loading = false
      })
    },
    handleMainFormCancelClick () {
      const _this = this
      _this.$router.go(-1)
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          const csca =_this.mainForm.data.category_sub_categories_attributes
          for (const key in csca) {
            if (!csca[key].shop_sub_category_id) {
              _this.$message({
                type: 'warning',
                message: _this.$t('form.product.selectASubcategory')
              })
              return false
            }
          }
          let requestParams = {}
          Object.assign(requestParams, _this.mainForm.data)
          requestParams = {
            shop_category: {
              ...requestParams
            }
          }
          _this.setGlobalLoading(true)
          shopCategoriesApi.putShopCategories(_this.urlParams.id, requestParams).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            setTimeout(() => {
              _this.$router.push({
                name: 'goodsManagerCategoriesList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          }).catch(() => _this.setGlobalLoading(false))
        } else {
          this.$message.error(this.$t('message.formIsRequire'))
          return false
        }
      })
    }
  }
}
